import { render, staticRenderFns } from "./auth.vue?vue&type=template&id=26191b56&"
import script from "./auth.vue?vue&type=script&lang=js&"
export * from "./auth.vue?vue&type=script&lang=js&"
import style0 from "./auth.vue?vue&type=style&index=0&id=26191b56&prod&module=true&lang=scss&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

/* custom blocks */
import block0 from "./auth.vue?vue&type=custom&index=0&blockType=i18n&locale=en-us&lang=yaml"
if (typeof block0 === 'function') block0(component)
import block1 from "./auth.vue?vue&type=custom&index=1&blockType=i18n&locale=ja&lang=yaml"
if (typeof block1 === 'function') block1(component)

export default component.exports