
import styles from '@/styles/landing/auth.scss?module';
import config from '@/config';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      counter: 0
    };
  },
  computed: {
    rootClasses() {
      const { $style } = this;
      return [$style.root, styles.root, 'bg-white'];
    },
    iconClasses() {
      const { $style } = this;
      const status = localStorage.getItem('status');
      if (status == 'production') {
        return [$style.icon, styles.icon, 'bg-white'];
      } else if (status == 'staging') {
        return [$style.icon, styles.icon, 'bg-yellow'];
      } else {
        return [$style.icon, styles.icon, 'bg-white'];
      }
    },
    ...mapGetters('user', ['getLocale'])
  },
  watch: {
    getLocale() {
      this.bootIntercom();
    }
  },
  mounted() {
    this.bootIntercom();
  },
  methods: {
    openFlowSolutions() {
      window.open('https://www.flow-solutions.com/', '_blank');
    },
    openFAQ() {
      window.open(
        `https://intercom.help/flow-solutions/${
          this.getLocale === 'en' ? 'en' : 'ja'
        }/`,
        '_blank'
      );
    },
    openSupport() {
      window.open('https://www.flow-solutions.com/support?hsLang=ja', '_blank');
    },
    bootIntercom() {
  /*    this.$intercom.shutdown();
      this.$intercom.boot({
        language_override: this.getLocale
      });*/
    },
    openLink() {
      window.open(
        'https://share.hsforms.com/1NIdeNbaiR2GcoYoJ-6KiIw12tnr',
        '_blank'
      );
    },
    openCounter() {
      this.counter++;
      const status1 = localStorage.getItem('status');

      if (this.counter == 5 && status1 == 'production') {
        const configData = require('../config/enviornment.json');
        const finalUrls = configData.http.staging;
        localStorage.setItem('sso', finalUrls.sso);
        localStorage.setItem('core', finalUrls.core);
        localStorage.setItem('flowgo', finalUrls.flowgo);
        localStorage.setItem('flowgo2', finalUrls.flowgo2);

        localStorage.setItem('eddyUrl', finalUrls.eddyUrl);
        localStorage.setItem('reporting', finalUrls.reporting);
        localStorage.setItem('status', finalUrls.status);

        localStorage.setItem('chatIP', finalUrls.chatIP);
        localStorage.setItem('chatIsProd', finalUrls.chatIsProd);

        alert('you are on stagging mode');
        this.$router.go();
      } else if (this.counter == 5 && status1 == 'staging') {
        const configData = require('../config/enviornment.json');
        const finalUrls = configData.http.production;
        localStorage.setItem('sso', finalUrls.sso);
        localStorage.setItem('eddyUrl', finalUrls.eddyUrl);
        localStorage.setItem('core', finalUrls.core);
        localStorage.setItem('flowgo', finalUrls.flowgo);
        localStorage.setItem('flowgo2', finalUrls.flowgo2);

        localStorage.setItem('reporting', finalUrls.reporting);
        localStorage.setItem('status', finalUrls.status);

        localStorage.setItem('chatIP', finalUrls.chatIP);
        localStorage.setItem('chatIsProd', finalUrls.chatIsProd);

        alert('you are on Production mode');
        this.$router.go();
      }
    }
  }
};
